import {
	faAward,
	faChartLine,
	faCheckSquare,
	faChild,
	faClock,
	faCoins,
	faEnvelope,
	faFrown,
	faHandshake,
	faListOl,
	faMinusCircle,
	faPlusCircle,
	faSmile,
	faUserTie,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Disclosure, Transition } from "@headlessui/react"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { useRef } from "react"
import { useState } from "react"
import { Fade } from "react-reveal"
import Jump from "react-reveal/Jump"
import { FormWrapper } from "../components/Forms"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StickyContainer, Sticky } from "react-sticky"
import { default as StickyEl } from "react-sticky-el"
import Scrollspy from "react-scrollspy"

const data = {
	text: [
		"Recruiting Seminare",
		"Recruiting in der Praxis erleben",
		"Termin buchen",
		"Austausch & Klärung offener Fragen",
		"Pragmatisch & motiviert durchstarten",
		"Recruiting Seminare - es könnte sich lohnen!",
		"2.500 €",
		"Es fallen keine weiteren Kosten für Anreise, Übernachtung, Spesen etc. an.",
		"Angebotsanfrage - Recruiting Seminare",
		"Tagessatz",
	],
	richText: [
		"<p>Jede Recruitingstrategie ist nur so wertvoll, wie sie auch angewandt und umgesetzt wird. Unsere Recruiting Seminare sind nach dem Baukastenprinzip aufgebaut – Sie entscheiden, welche Themen behandelt werden sollten, wir konzipieren und schulen: motivierend und praxisnah.</p><p><b>Die Themen im Recruiting zu schulen, die Sie wirklich bewegen: beginnend von der telefonischen Bewerberannahme bis hin zur erfolgreichen Vertragsverhandlung mit Top-Performern.</b></p>",
		"Buchen Sie einen Beratungstermin – selbstverständlich kostenlos und unverbindlich.",
		"Wir zeigen Ihnen Beispiele von erfolgreichen Recruiting Seminaren und deren Inhalte auf. Anschließend erhalten Sie ein individuelles Angebot mit allen besprochenen Seminarthemen.",
		"<p>Ihre Recruiter sind <strong>geschult, motiviert</strong> und wollen deutlich <strong>erfolgreicher und strukturierter Mitarbeiter recruiten</strong>.</p>",
		"Rechnen Sie selbst, ob sich ein Recruiting Seminar für Ihr Unternehmen rechnen könnte:",
		"Tragen Sie bitte ein, wie viel es Ihnen wert wäre, wenn Ihre Recruiter nach dem Seminar<strong> 50-80 % erfolgreicher recruiten würden als vorher</strong>:",
		"<p>Eventuell sind unsere <a href='/recruiting-kpi-motivation'>Recruiting KPI`s &amp; Motivation</a> interessant für Sie?</p>",
	],
	boolean: [false, true, false],
	number: [3000],
	multiple: [
		[
			{
				text: ["Baukastenprinzip"],
				richText: [
					"Alle Seminarinhalte werden auf Ihre Wünsche zugeschnitten, wir legen im Vorfeld die Schwerpunkte und Ziele gemeinsam fest und konzipieren das Seminar.",
				],
				boolean: [true],
			},
			{
				text: ["Branchenbezogen"],
				richText: [
					"Der Inhalt wird individuell auf Ihre Branche und die dazugehörigen Berufsgruppen konzipiert.",
				],
				boolean: [true],
			},
			{
				text: ["Workshop-Charakter"],
				richText: [
					"Unsere Seminare haben alle Workshop-Charakter, d. h. die Teilnehmer werden eingebunden anstatt sich den ganzen Tag „einen Vortrag anhören zu müssen“.",
				],
				boolean: [true],
			},
			{
				text: ["Rollenspiele"],
				richText: [
					"Ob mit oder ohne Kamera – Rollenspiele bleiben im Kopf und spiegeln praxisnahe Situationen wider.",
				],
				boolean: [true],
			},
		],
	],
}

const Page = () => {
	const [amortisation, setAmortisation] = useState()
	const input = useRef()

	return (
		<Layout>
			<Seo
				title="Recruiting Seminare - Für Einsteiger & Profis"
				description="Recruiting Seminare: ✔️ vom Profi ✔️ Lerninhalte individuell konfigurierbar ✔️ über 20 Jahre Erfahrung – Angebot hier!"
			/>
			<StickyContainer className="w-full">
				<div className="bg-white w-full">
					<div className="sectionwidth sectionpadding grid grid-cols-1 lg:grid-cols-4 gap-24">
						<div className="lg:col-span-3">
							<h1
								className={
									"h1_5 font-bold text-white text-center p-4 rounded-full " +
									(data.boolean[0] ? "bg-primary" : "") +
									(data.boolean[1] ? "bg-secondary-800" : "") +
									(data.boolean[2] ? "bg-primary-900" : "")
								}
							>
								{data.text[0]}
								<span className="block h3">{data.text[1]}</span>
							</h1>
							<StaticImage
								className="border-2 my-8 border-primary rounded-lg shadow-lg w-full sm:h-80"
								quality={100}
								src="../images/recruiting-seminare.png"
								alt="Karriere Website"
								placeholder="tracedSVG"
							/>
							<div
								className="prose max-w-full mb-4"
								dangerouslySetInnerHTML={{ __html: data.richText[0] }}
							></div>
							<StickyEl
								topOffset={-107.59}
								stickyStyle={{ zIndex: "10" }}
								stickyClassName="lg:mt-[116px] md:mt-[115.59px] mt-[96px]"
							>
								<div className="w-full flex justify-center">
									<Scrollspy
										items={[
											"vorteile",
											"ablauf",
											"amortisation",
											"kosten",
											"angebot",
										]}
										offset={-200}
										className="border-2 border-t-0 border-secondary rounded-b-lg overflow-hidden !list-none flex"
										currentClassName="bg-secondary"
									>
										<li className="bg-primary hover:bg-secondary text-white transition-all flex">
											<AnchorLink className="py-2 px-4 md:px-2" to="#vorteile">
												<FontAwesomeIcon icon={faCheckSquare} />{" "}
												<span className="hidden md:inline">Vorteile</span>
											</AnchorLink>
										</li>
										<li className="bg-primary hover:bg-secondary text-white transition-all flex">
											<AnchorLink className="py-2 px-4 md:px-2" to="#ablauf">
												<FontAwesomeIcon icon={faListOl} />{" "}
												<span className="hidden md:inline">Ablauf</span>
											</AnchorLink>
										</li>

										<li className="bg-primary hover:bg-secondary text-white transition-all flex">
											<AnchorLink
												className="py-2 px-4 md:px-2"
												to="#amortisation"
											>
												<FontAwesomeIcon icon={faClock} />{" "}
												<span className="hidden md:inline">Amortisation</span>
											</AnchorLink>
										</li>
										<li className="bg-primary hover:bg-secondary text-white transition-all flex">
											<AnchorLink className="py-2 px-4 md:px-2" to="#kosten">
												<FontAwesomeIcon icon={faCoins} />{" "}
												<span className="hidden md:inline">Kosten</span>
											</AnchorLink>
										</li>
										<li className="bg-primary hover:bg-secondary text-white transition-all flex">
											<AnchorLink className="py-2 px-4 md:px-2" to="#angebot">
												<FontAwesomeIcon icon={faEnvelope} />{" "}
												<span className="hidden md:inline">Angebot</span>
											</AnchorLink>
										</li>
									</Scrollspy>
								</div>
							</StickyEl>
						</div>
						<div className="hidden lg:block">
							<Sticky topOffset={-70}>
								{({ style, isSticky, distanceFromBottom }) => (
									<div
										className=""
										style={{
											...style,
											marginTop: isSticky
												? distanceFromBottom > 120
													? 120 + "px"
													: distanceFromBottom + "px"
												: "",
										}}
									>
										<div className="bg-white rounded-lg shadow-xl border-2 border-secondary p-4 mx-auto w-60">
											<h2 className="text-primary font-bold text-center mb-4">
												Unsere Team-Expertise im Recruiting:
											</h2>
											<div className="space-y-6">
												<div className="flex items-start text-sm">
													<FontAwesomeIcon
														icon={faAward}
														className="mr-2 text-secondary"
														size="lg"
													/>
													<span>
														<strong>46 Jahre</strong> im operativen Recruiting
													</span>
												</div>
												<div className="flex items-start text-sm">
													<FontAwesomeIcon
														icon={faAward}
														className="mr-2 text-secondary"
														size="lg"
													/>
													<span>
														<strong>20 Jahre</strong> in der beratenden
														Recruiting-Optimierung
													</span>
												</div>
												<div className="flex items-start text-sm">
													<FontAwesomeIcon
														icon={faAward}
														className="mr-2 text-secondary"
														size="lg"
													/>
													<span>
														In über <strong>30 verschiedenen Branchen</strong>
													</span>
												</div>
											</div>
										</div>
									</div>
								)}
							</Sticky>
						</div>
					</div>
				</div>

				<div className="bg-gray-100 w-full" id="vorteile">
					<div className="sectionwidth sectionpadding grid grid-cols-1 lg:grid-cols-4 gap-24">
						<div className="lg:col-span-3">
							<h2 className="h1_5 text-primary font-bold text-center mb-8">
								<FontAwesomeIcon icon={faCheckSquare} /> Alle Vorteile auf einen
								Blick
							</h2>
							<div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
								{data.multiple[0].map((item, index) => (
									<Fade key={index} left delay={index * 100}>
										<div>
											<Disclosure
												className="w-full p-2 bg-white shadow-lg"
												style={{
													animation: item.boolean[0]
														? "shake-slow 20s linear " +
														  index * 5 +
														  "s infinite"
														: "",
												}}
												key={index}
												as="div"
											>
												{({ open }) => (
													<>
														<Disclosure.Button className="flex text-lg justify-between items-center w-full px-4 py-1 font-bold text-left rounded-lg hover:bg-primary-50 focus:outline-none">
															<div className="flex items-center gap-4 break-all">
																<FontAwesomeIcon
																	icon={faCheckSquare}
																	className={
																		item.boolean[0]
																			? "text-secondary"
																			: "text-primary"
																	}
																	size="2x"
																/>
																{item.text}
															</div>
															<FontAwesomeIcon
																className="text-gray-300"
																icon={open ? faMinusCircle : faPlusCircle}
															/>
														</Disclosure.Button>

														<Transition
															className="overflow-hidden"
															enter="transition-all duration-700 ease-out"
															enterFrom="transform scale-95 opacity-0 max-h-0"
															enterTo="transform scale-100 opacity-100 max-h-40"
															leave="transition-all duration-500 ease-out"
															leaveFrom="transform scale-100 opacity-100 max-h-40"
															leaveTo="transform scale-95 opacity-0 max-h-0"
														>
															<Disclosure.Panel
																className="p-2"
																dangerouslySetInnerHTML={{
																	__html: item.richText,
																}}
															></Disclosure.Panel>
														</Transition>
													</>
												)}
											</Disclosure>
										</div>
									</Fade>
								))}
							</div>
						</div>
					</div>
				</div>

				<div className="bg-white w-full" id="ablauf">
					<div className="sectionwidth sectionpadding grid grid-cols-1 lg:grid-cols-4 gap-24">
						<div className="lg:col-span-3">
							<h2 className="h1_5 text-primary font-bold text-center mb-8">
								<FontAwesomeIcon icon={faListOl} /> Ablauf
							</h2>
							<div className="flex flex-col lg:flex-row justify-between mt-4 lg:mt-16 border-t border-gray-500 gap-8 mb-4">
								{[...Array(3)].map((item, index) => (
									<Fade key={index} left delay={index * 300}>
										<div className="flex flex-col items-center justify-start flex-1">
											<div className="h-20 w-20 flex justify-center items-center text-4xl bg-secondary text-white font-bold rounded-full mt-4 lg:-mt-10">
												{index + 1}
											</div>

											<h3 className="h3 text-primary my-2 font-bold text-center">
												{data.text[index + 2]}
											</h3>
											<div
												className="prose text-center max-w-full mb-2"
												dangerouslySetInnerHTML={{
													__html: data.richText[index + 1],
												}}
											></div>
											{index === 0 && (
												<a className="btn btn-secondary" href="/termin">
													Termin buchen
												</a>
											)}
										</div>
									</Fade>
								))}
							</div>
						</div>
					</div>
				</div>

				<div className="bg-gray-100 w-full" id="amortisation">
					<div className="sectionwidth sectionpadding grid grid-cols-1 lg:grid-cols-4 gap-24">
						<div className="lg:col-span-3">
							<h2 className="h1_5 text-primary font-bold text-center mb-4">
								<FontAwesomeIcon icon={faClock} /> Amortisationsrechner
							</h2>
							<div
								className="prose max-w-full text-center mb-8"
								dangerouslySetInnerHTML={{ __html: data.richText[4] }}
							></div>
							<div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
								<div className="shadow-xl bg-white flex-col items-center p-4 text-center mx-8">
									<FontAwesomeIcon
										icon={faChartLine}
										size="3x"
										className="text-secondary"
									/>
									<h3 className="h3 font-bold text-primary">
										Amortisationsrechner:
									</h3>
									<span
										className="prose max-w-full"
										dangerouslySetInnerHTML={{ __html: data.richText[5] }}
									></span>
									<form
										onSubmit={(e) => {
											e.preventDefault()
											if (input.current?.value > 0) {
												setAmortisation(
													amortisation === input.current?.value
														? input.current?.value - 1
														: input.current?.value
												)
											}
										}}
										className="space-y-8 mt-8"
										noValidate
									>
										<div className="font-bold text-lg flex items-center">
											<input
												step="1000"
												type="number"
												min="0"
												placeholder="Betrag eintragen"
												className="border-0 border-b-2 font-bold text-lg placeholder-gray-300 text-right focus:border-secondary focus:outline-none focus:ring-0 w-full flex-grow"
												ref={input}
											></input>
											€
										</div>

										<button className="btn btn-secondary" type="submit">
											Berechnen
										</button>
									</form>
								</div>
								<div className="flex justify-center items-center mx-8">
									<Fade
										right
										when={!!amortisation}
										spy={amortisation >= data.number[0]}
									>
										<Jump spy={amortisation}>
											<div className="bg-white rounded-full shadow-xl flex-col items-center px-16 py-8 text-center space-y-4">
												<FontAwesomeIcon
													icon={
														amortisation >= data.number[0] ? faSmile : faFrown
													}
													className={
														amortisation >= data.number[0]
															? "text-green-600"
															: "text-red-400"
													}
													size="5x"
												/>
												<h3
													className={
														"h4 font-bold " +
														(amortisation >= data.number[0]
															? "text-green-600"
															: "text-red-400")
													}
												>
													{amortisation >= data.number[0]
														? data.text[5]
														: "Eher nicht!"}
												</h3>
												<span
													className="prose prose-primary max-w-full"
													dangerouslySetInnerHTML={{
														__html:
															amortisation >= data.number[0]
																? "Wir freuen uns auf Ihre Kontaktaufnahme."
																: data.richText[6],
													}}
												></span>
											</div>
										</Jump>
									</Fade>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="bg-white w-full" id="kosten">
					<div className="sectionwidth sectionpadding grid grid-cols-1 lg:grid-cols-4 gap-24">
						<div className="lg:col-span-3">
							<h2 className="h1_5 text-primary font-bold text-center mb-4">
								<FontAwesomeIcon icon={faCoins} /> Kosten
							</h2>
							<div className="bg-white shadow-2xl grid grid-cols-1 lg:grid-cols-2 p-4">
								<div className="text-center lg:text-right p-4 lg:pl-24 border-gray-700 border-b lg:border-b-0 lg:border-r">
									<h3 className="h3 font-bold text-primary">
										{data.text[9] || "Schätzpreis"}
									</h3>
									<span className="font-bold">{data.text[6]}</span>
									<p className="text-sm">
										Der Preis variiert abhängig von den konkreten Funktionen und
										Leistungsinhalten
									</p>
								</div>
								<div className="flex flex-col items-center gap-1 p-4">
									<AnchorLink to="#angebot" className="btn btn-primary">
										Angebot anfordern
									</AnchorLink>
									<span className="font-bold">oder</span>
									<Link to="/termin" className="btn btn-secondary">
										Termin buchen
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</StickyContainer>

			<div className="bg-gray-100 w-full" id="angebot">
				<div className="sectionwidth sectionpadding">
					<div className="p-8 bg-white shadow-xl">
						<h2 className="h1_5 text-primary font-bold text-center mb-4">
							<FontAwesomeIcon icon={faEnvelope} /> Wir unterbreiten Ihnen gerne
							ein Angebot!
						</h2>
						<p className="text-center mb-8">
							Kreuzen Sie die Punkte an, die Sie interessieren, füllen Sie das
							Kontaktformular aus & klicken Sie auf SENDEN.
						</p>
						<FormWrapper
							name="Angebot anfordern"
							successMessage="Vielen Dank, dass Sie uns kontaktiert haben! Wir werden uns schnellstmöglich bei Ihnen zurückmelden. Schöne Grüße, Ihr Team von Internes-Recruiting.de"
							subject={data.text[8]}
						>
							<div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
								{" "}
								<div className="bg-primary rounded-lg flex flex-col gap-2 p-3 text-white">
									<span className="font-bold">Bewerbergewinnung</span>
									<label className="inline-flex items-center text-xs sm:text-base">
										<input
											type="checkbox"
											name="Karriere Website"
											className="mr-2 checkbox"
											value="angekreuzt"
										/>
										Karriere Website
									</label>
									<label className="inline-flex items-center text-xs sm:text-base">
										<input
											type="checkbox"
											name="E-Recruiting-Analyse"
											className="mr-2 checkbox"
											value="angekreuzt"
										/>
										E-Recruiting-Analyse
									</label>
									<label className="inline-flex items-center text-xs sm:text-base">
										<input
											type="checkbox"
											name="Active Sourcing"
											className="mr-2 checkbox"
											value="angekreuzt"
										/>
										Active Sourcing
									</label>
								</div>
								<div className="bg-secondary-800 rounded-lg flex flex-col gap-2 p-3 text-white">
									<span className="font-bold">Recruiting Beratung</span>

									<label className="inline-flex items-center text-xs sm:text-base">
										<input
											type="checkbox"
											name="Recruiting Strategie"
											className="mr-2 checkbox"
											value="angekreuzt"
										/>
										Recruiting Strategie
									</label>
									<label className="inline-flex items-center text-xs sm:text-base">
										<input
											type="checkbox"
											name="Recruiting Impulse"
											className="mr-2 checkbox"
											value="angekreuzt"
										/>
										Recruiting Impulse
									</label>
									<label className="inline-flex items-center text-xs sm:text-base">
										<input
											type="checkbox"
											name="Recruiting Seminare"
											className="mr-2 checkbox"
											value="angekreuzt"
										/>
										Recruiting Seminare
									</label>
								</div>
								<div className="bg-primary-900 rounded-lg flex flex-col gap-2 p-3 text-white">
									<span className="font-bold">Bewerbergewinnung</span>
									<label className="inline-flex items-center text-xs sm:text-base">
										<input
											type="checkbox"
											name="Motivation & KPI´s"
											className="mr-2 checkbox"
											value="angekreuzt"
										/>
										Motivation & KPI´s
									</label>
									<label className="inline-flex items-center text-xs sm:text-base">
										<input
											type="checkbox"
											name="Recruitervermittlung"
											className="mr-2 checkbox"
											value="angekreuzt"
										/>
										Recruitervermittlung
									</label>
									<label className="inline-flex items-center text-xs sm:text-base">
										<input
											type="checkbox"
											name="Recruiter mieten"
											className="mr-2 checkbox"
											value="angekreuzt"
										/>
										Recruiter mieten
									</label>
								</div>
								<div className="flex flex-col gap-2 p-3">
									<span className="font-bold">Sonstiges</span>
									<label className="inline-flex items-center text-xs sm:text-base">
										<input
											type="checkbox"
											name="Individuelles Angebot"
											className="mr-2 checkbox"
											value="angekreuzt"
										/>
										Individuelles Angebot
									</label>
								</div>
								<label className="flex flex-col text-gray-500">
									Name*
									<input
										className="input text-black"
										type="text"
										name="name"
										required
									/>
								</label>
								<label className="flex flex-col text-gray-500">
									Geschäftliche E-Mail*
									<input
										className="input text-black"
										type="email"
										name="email"
										required
									/>
								</label>
								<label className="flex flex-col text-gray-500">
									Telefon
									<input
										required
										className="input text-black"
										type="text"
										name="Telefon"
									/>
								</label>
								<label className="flex flex-col text-gray-500">
									Firma
									<input
										className="input text-black"
										type="text"
										name="Firma"
									/>
								</label>
							</div>

							<label className="flex flex-col text-gray-500 mb-3 mt-2">
								Nachricht
								<textarea
									className="input text-black"
									rows="8"
									name="nachricht"
								/>
							</label>
						</FormWrapper>
					</div>
				</div>
			</div>

			<div className="bg-white w-full">
				<div className="sectionwidth sectionpadding">
					<h2 className="h1_5 mb-8 font-bold text-primary text-center w-full">
						Weitere Recruitinglösungen
					</h2>

					<div className="flex flex-col md:flex-row -mx-2 sm:mx-0">
						<div className="py-8 px-3 text-center space-y-4 flex-grow bg-primary text-white transform transition-transform hover:z-10 hover:scale-105">
							<FontAwesomeIcon icon={faChild} size="3x" />
							<h3 className="h3 font-bold">Bewerbergewinnung</h3>
							<p>
								So erhalten Sie zukünftig{" "}
								<strong>schneller &amp; mehr passende Bewerber</strong>. Unsere
								Analyse zeigt Ihnen Lösungswege für eine erfolgreichere
								Personalgewinnung auf.
							</p>
							<div className="flex flex-col gap-2 items-center">
								<a
									href="/karriere-website-kosten-angebot"
									className="text-primary bg-white p-2 font-bold transition-all rounded-full border border-white hover:bg-primary hover:text-white"
								>
									Karriere-Website
								</a>
								<a
									href="/e-recruiting-analyse-kosten-sparen"
									className="text-primary bg-white p-2 font-bold transition-all rounded-full border border-white hover:bg-primary hover:text-white"
								>
									E-Recruiting-Analyse
								</a>
								<a
									href="/active-sourcing-konzept-zum-erfolg"
									className="text-primary bg-white p-2 font-bold transition-all rounded-full border border-white hover:bg-primary hover:text-white"
								>
									Active-Sourcing
								</a>
							</div>
						</div>

						<div className="py-8 px-3 text-center space-y-4 flex-grow bg-secondary-800 text-white transform transition-transform hover:z-10 hover:scale-105">
							<FontAwesomeIcon icon={faHandshake} size="3x" />
							<h3 className="h3 font-bold">Recruiting Beratung</h3>
							<p>
								So lösen Sie Ihre Recruitingthemen nachhaltig, erhalten{" "}
								<strong>neue</strong> <strong>Impulse</strong> und bringen Ihr
								Recruitingteam durch <strong>Schulungen</strong> und{" "}
								<strong>Workshops</strong> nach vorne.
							</p>

							<div className="flex flex-col gap-2 items-center">
								<a
									href="/recruiting-strategie-kmu"
									className="text-secondary-800 bg-white p-2 font-bold transition-all rounded-full border border-white hover:bg-secondary-800 hover:text-white"
								>
									Recruiting Strategie
								</a>
								<a
									href="/recruiting-impulse-neue-ideen"
									className="text-secondary-800 bg-white p-2 font-bold transition-all rounded-full border border-white hover:bg-secondary-800 hover:text-white"
								>
									Recruiting Impulse
								</a>
								<a
									href="/recruiting-seminare-fuer-recruiter"
									className="text-secondary-800 bg-white p-2 font-bold transition-all rounded-full border border-white hover:bg-secondary-800 hover:text-white"
								>
									Recruiting Seminare
								</a>
							</div>
						</div>

						<div className="py-8 px-3 text-center space-y-4 flex-grow bg-primary-900 text-white transform transition-transform hover:z-10 hover:scale-105">
							<FontAwesomeIcon icon={faUserTie} size="3x" />
							<h3 className="h3 font-bold">Recruiting in Aktion</h3>
							<p>
								So wird die <strong>Führung</strong> Ihres{" "}
								<strong>Recruitingteams</strong> sachlich, motivierend und
								zielführend. Wenn Sie einen <strong>Recruiter suchen</strong>,
								finden wir diesen.
							</p>

							<div className="flex flex-col gap-2 items-center">
								<a
									href="/recruiting-kpi-motivation"
									className="text-primary-900 bg-white p-2 font-bold transition-all rounded-full border border-white hover:bg-primary-900 hover:text-white"
								>
									Recruiting KPI´s & Motivation
								</a>
								<a
									href="/recruiter-vermittlung-festanstellung"
									className="text-primary-900 bg-white p-2 font-bold transition-all rounded-full border border-white hover:bg-primary-900 hover:text-white"
								>
									Recruitervermittlung
								</a>
								<a
									href="/recruiter-mieten-und-rpo-laeuft"
									className="text-primary-900 bg-white p-2 font-bold transition-all rounded-full border border-white hover:bg-primary-900 hover:text-white"
								>
									Recruiter mieten
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default Page
